/* eslint-disable no-undef */
import React, { useEffect, useState } from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import UseCases from "@components/pageScore/pageContactCenterOmnicanal/components/useCases/useCases"
import SilderPricing from "@components/pageThanks/pageThanksTicker/components/silderPricing/silderPricing"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import data from "@components/pageTicker/pageSolucionOmnicanal/data"
import Start from "@components/pageScore/pageContactCenterOmnicanal/components/intro/intro"
import Integrations from "@components/pageTicker/integrations/integrations"
import CardsImg from "@components/pageTicker/pageSolucionOmnicanal/components/cardImgTwo/cardImgTwo"
import TitleUnderlineTestimony from "@components/pageTicker/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"

const StructureMainPage = ({ location }) => {
    //velocidad de entrada de textos y animaciones para desktop y mobile
    const windowsWidth = true

    const [ctaBanner, setctaBanner] = useState(false)
    useEffect(() => {
        let windowWidth = window.screen.width
        if (windowWidth < 900) {
            setctaBanner(false)
        } else {
            setctaBanner(false)
        }
    }, [])


    const titleTestimony = <p>
        Empresas que  <TitleUnderlineTestimony underline="automatizaron sus" /> operaciones con Beex</p>

    const iconsSilder = i => {
        switch (i) {
            case 0:
                return finanzas
            case 1:
                return ecommerce
            case 2:
                return concesionarias
            case 3:
                return logistica
            case 4:
                return educacion
            default:
                return ""
        }
    }
    const titleSilder = i => {
        switch (i) {
            case 0:
                return "Finanzas"
            case 1:
                return "Ecommerce "
            case 2:
                return "Concesionarias "
            case 3:
                return "Logística"
            case 4:
                return "Educación"
            default:
                return "Conoce otros casos de éxito"
        }
    }

    return (
        <div className="fnd">
            <section>
                <BannerCookies />
                <div>
                    <Header
                        path="/ticker/"
                        location={location}
                        bannerCTA={ctaBanner}
                        windowsWidth={windowsWidth}
                    />
                </div>
                <div className="container">
                    <Start data={data.start} location={location} ticker={true} />
                    <UseCases data={data.modules} location={location} data1={data.useCases} />
                    <SilderPricing data={data.pricing} location={location} />
                    <CardsImg data={data.cards} location={location} dataText={data.cardsText} />
                    <BannerCTA
                        title="Empieza a mejorar la comunicación con tus clientes, ahora mismo"
                        text="Accede a una demo total y comprueba como te ayudaría nuestra tecnología omnicanal."
                        link="/hablemos/"
                        textButton="Comunicarme con ventas"
                    />
                    <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
                    <Integrations location={location} />
                    <Footer data={data.footer} location={location} />
                    <FooterMobile data={data.footer} location={location} />
                </div>
            </section>
        </div>
    )
}

export default StructureMainPage
