/* eslint-disable no-undef */
import React from "react"
import StructurePageInbox from "@components/pageTicker/pageSolucionOmnicanal/template"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div>
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Beex: La solución omnicanal para tu empresa [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/conversations/solucion-omnicanal/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Encuentra la solución omnicanal que siempre has buscado. Integra todos tus canales de atención con las herramientas internas de tu empresa."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Beex: La solución omnicanal para tu empresa"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/conversations/solucion-omnicanal/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/og-ticker.png`}
      />
      <meta
        property="og:description"
        content="Encuentra la solución omnicanal que siempre has buscado. Integra todos tus canales de atención con las herramientas internas de tu empresa."
      />
    </Helmet>
    <StructurePageInbox location={location} />
  </div>
)

export default IndexPage
