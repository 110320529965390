import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import TitleUnderline from "./titleUnderline"


const Skills = ({ data, data1, alternative }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 14000,
  }
  return(
  <section className={"container-useCases-pagesA-B"} ref={fromRef}>
    <section className="container-cards-whatsapp ">
    <p className="container-experience-ticker-title">
      Lo que hará Beex con tu  <TitleUnderline underline="Contact Center" />
      </p>
      <div className="row-whatsapp-functions">
        {data.map(elemento => {
          return (
            <div key={elemento.title} className="card-cases-use">
              <div className="card-img1">
                <img
                  loading="lazy"
                  src={elemento.img}
                  className={`card-icon ${elemento.classImage}`}
                  alt="..."
                />
              </div>
              <h3
                className="card-title-inbox center-text"
                style={{ fontWeight: "bold" }}
              >
                {elemento.title}{" "}
              </h3>
              <p className="card-body1 center-text">{elemento.info}</p>
            </div>
          )
        })}
      </div>
    </section>

    <div className="row-moduledfss">
    <Slider {...activateSliderTestimonies} ref={sliderRef}>
        {data.map(elemento => {
          return (
            <div key={elemento.title} className="module">
              <div className={`modules ${elemento.module}`}>
                <div className="modules-img">
                  <img
                    src={elemento.img}
                    className="modules-icon"
                  />
                </div>
                <br />
                <p className="modules-title center-text">
                  {elemento.title}{" "}
                </p>
                <p className="modules-body center-text">{elemento.info}</p>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  </section>
)
      }
export default Skills
